html, body, div, span, iframe,
h1, h2, h3, h4, h5, h6, p, a, address, 
img, small, var, ol, ul, li, fieldset, 
form, label, legend, table, caption, tbody, 
tfoot, thead, tr, th, td, article, aside, 
canvas, details, figure, figcaption, footer, 
header, hgroup, menu, nav, output, section, 
summary, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

html {
  background-color: #3a415a;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --background--btn--add-todo: #34344e;
  --background--btn: #e9fccf ;
  --background--btn-delete: #e87474;
  --background--input: #c1e0e0; 
  --background--list: #ffffff;
  --background--form: #cbdad5;
  --text-color-landPage: #1c140d;
  --text-color: #ffffff;
}
